<script setup>
  import { ref, onMounted } from 'vue';
  import { usePage, router } from '@inertiajs/vue3';

  let params = ref();
  if (typeof window === 'object') params = new URLSearchParams(window?.location.search);
  else params = new URLSearchParams();

  const isSearchHovered = ref(false);
  const isFilterHovered = ref(false);
  const isFormVisible = ref(false);

  const handleSelectChange = () => {
    const search = document.querySelector('input[name="search"]').value;
    const filter = document.querySelector('select[name="filter"]').value;
    router.visit(route('search', { search: search, filter: filter }));
  };

  onMounted(() => {
    const search = params.get('search');
    if (search) {
      document.querySelector('input[name="search"]').value = search;
    }
    const filter = params.get('filter');
    if (filter) {
      document.querySelector('select[name="filter"]').value = filter;
    }
  });
</script>

<template>
  <button
    class="z-50 h-10 bg-transparent p-2 text-black hover:border-none hover:ring-0 md:hidden"
    @click="isFormVisible = !isFormVisible">
    <i class="fa fa-search"></i>
  </button>

  <form
    action="/search"
    :class="[
      isFormVisible ? 'absolute right-14 z-50 block w-3/4' : 'hidden',
      'lg::ml-10 md:relative md:z-auto md:mx-auto md:block md:w-full',
    ]"
    style="z-index: 99999">
    <div
      class="flex flex-row items-center overflow-hidden rounded-full border-2 border-gray-900"
      :class="{
        'bg-gray-50': !isSearchHovered && !isFilterHovered,
        'bg-gray-400': isSearchHovered || isFilterHovered,
      }">
      <button
        class="z-50 h-10 bg-transparent p-2 text-black hover:border-none hover:ring-0"
        @mouseover="
          isSearchHovered = false;
          isFilterHovered = false;
        "
        @mouseleave="
          !isFilterHovered && !isSearchHovered ? null : (isSearchHovered = false);
          isFilterHovered = false;
        ">
        <i class="fa fa-search"></i>
      </button>
      <div class="-ml-12 flex w-full flex-row border">
        <input
          type="text"
          placeholder="Search..."
          name="search"
          :class="[
            'w-full border-l-0 border-r-0 border-none pl-10 text-black hover:rounded-full focus:outline-none',
            isFilterHovered ? 'border-r-0 bg-gray-400' : 'bg-gray-50',
          ]"
          @mouseover="
            isSearchHovered = true;
            isFilterHovered = false;
          "
          @mouseleave="isSearchHovered = false" />
        <select
          name="filter"
          id="filter"
          :class="[
            'z-50 -mr-4 w-[75%] rounded-r-full border-l-0 border-none p-2 pr-10 text-black hover:rounded-full focus:outline-none',
            isSearchHovered ? 'bg-gray-400' : 'bg-gray-50',
          ]"
          @mouseover="
            isFilterHovered = true;
            isSearchHovered = false;
          "
          @mouseleave="isFilterHovered = false"
          @change="handleSelectChange">
          <option value="All">All Users</option>
          <option value="Performer">Performer</option>
          <option value="Producer">Producer</option>
          <option value="Crew">Crew</option>
          <option value="Photographer">Photographer/Videographer</option>
          <option value="gigs">Gigs</option>
        </select>
      </div>
    </div>
  </form>
</template>
